﻿//背景用色
$color-main:#01916d; //主题绿色
$color-main-dark:#026d52;
$color-main-light:#f2f4f8; 
$color-main-bg:#eee;

//border用色
$color-line:#b2b2b2;

//文字用色
$color-red:#ff0909; //错误提示文字颜色
$color-title:#000; //标题黑色
$color-text:#585858; //正文颜色  80%黑
$color-desc:#999999; //解释性文字颜色  60%黑

//文字大小
$text-size-sm-base:14px;
$text-size-sm-ratio:12 / 14;

$text-size-base:16px; //正文大小
$text-size-ratio:(lg:14px); //缩放

$text-size-md-base:18px; //列表头条内容文字大小
$text-size-md-ratio:(lg:16px, mmd:14px); //缩放

$text-size-lg-base:20px;
$text-size-lg-ratio:(lg:18px, mmd:16px);

//tag文字大小
$tag-size-base: 16px;
$tag-size-ratio: 14 / 16;

//标题大小，由小到大
$title-size-xs-base:22px;
$title-size-xs-ratio:(lg:20px, mmd:18px, md:16px);

$title-size-sm-base:26px;
$title-size-sm-ratio:(lg:22px, mmd:20px, md:16px);

$title-size-md-base:30px;
$title-size-md-ratio:(lg:26px, mmd:22px, md:18px);

$title-size-lg-base:34px;
$title-size-lg-ratio:(lg:30px, mmd:26px, md:18px);

// 动画效果持续时间
$anime-duration: 0.4s;
//贝塞尔曲线
$anime-bezier:cubic-bezier(0.77, 0, 0.175, 1);

//header高度
$header-height-base: 135px;
$header-height-ratio: 60 / 135;

//内容部分左右留白
$container-gutter-base:80px;
$container-gutter-ratio:10 / 80;

//阴影
$shadow-offset: 0px 3px;
$shadow-spread: 10px;
$shadow-color:#dbdada;

$jason-map-width: 400px !default;
$jason-map-duration: .5s !default;
$jason-map-theme: #ddd0b5 !default;

//汉堡包按钮参数设置
$hamburger-layer-width: 28px; //汉堡包按钮每一条线的宽度
$hamburger-layer-height: 3px; //汉堡包按钮每一条线的高度
$hamburger-layer-spacing: 5px; //汉堡包按钮每一条线的高度间隔
$hamburger-layer-color: $color-main; //汉堡包按钮线的颜色
$hamburger-layer-border-radius: 0; //汉堡包按钮线的圆角效果

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: ( // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 720px),
  // 小屏
  md: (min-width: 992px, container-width: 960px),
  // 中屏
  mmd: (min-width: 1025px, container-width: 1000px),
  // 大屏
  lg: (min-width: 1367px, container-width: 1230px),

  llg: (min-width: 1601px, container-width: 1230px)) !default;
