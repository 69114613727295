@font-face {
  font-family: "iconfont"; /* Project id 3566158 */
  src: url('iconfont.woff2?t=1660012032878') format('woff2'),
       url('iconfont.woff?t=1660012032878') format('woff'),
       url('iconfont.ttf?t=1660012032878') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-homepage:before {
  content: "\e702";
}

.icon-angleleft:before {
  content: "\e8ec";
}

.icon-angleright:before {
  content: "\e8ee";
}

.icon-duoyuyan:before {
  content: "\e654";
}

.icon-facebookfacebook11:before {
  content: "\e735";
}

