@import "../../assets/styles/utils";
footer {
    width: 100%;
    @include res(height, 535px,(mmd:500px,md:400px,xs:450px));
    background: #3E3E3E;
    position: relative;
    .container {
        .footer-nav {
            @include res(padding-top,95px,40 / 95);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            dl {
                @include res(width, fit-content, (xs:50%));
                @include res(margin-bottom, 0, (xs:20px));
                dt {
                    @include res(margin-bottom, 25px, 15 / 25);
                    a {
                        transition: all .3s;
                        display: block;
                        font-family: 'ProximaNova-Black';
                        @include res(font-size, 36px, 24 / 36);
                        color: #ffffff;
                    }
                }
                dd {
                    transition: all .3s;
                    @include res(margin-bottom, 15px, 5 / 15);
                    a {
                        transition: all .3s;
                        display: block;
                        font-family: 'ProximaNova-Bold';
                        @include res(font-size, 20px, 16 / 20);
                        color: #bcbcbc;
                        i {
                            display: block;
                            @include res(font-size, 34px, 26 / 34);
                            color: #ffffff;
                        }
                    }
                    &:hover {
                        a {
                            color: #ffffff;
                        }
                    }
                    &.email {
                        @include res(margin-bottom, 25px, 15 / 25);
                        a {
                            font-family: 'ProximaNova';
                        }
                    }
                    &:hover {
                        transform: translateX(5px);
                    }
                }
                &:last-child {
                    dd {
                        &:hover {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
    }
    .footer-copy {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 124px;
        .container {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 85px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .left {
                @include res(font-size,16px, 14 / 16);
                @include res(text-align, left, (xs:center));
                @include res(margin, 0, (xs:auto));
                color: #707070;
            }
            .right {
                @include res(font-size, 16px, 14 / 16);
                @include res(text-align, left, (xs:center));
                @include res(margin, 0, (xs:auto));
                color: #707070;
                .site-map {
                    margin-right: 5px;
                }
                a {
                    transition: all .3s;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}