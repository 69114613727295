@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/Proxima Nova Bold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Black';
  src: url('../fonts/ProximaNova-Black.otf');
  font-weight: normal;
  font-style: normal;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'ProximaNova', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1200px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}