@import "../../assets/styles/utils";
header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include res(height, 140px, (mmd:80px));
    z-index: 9999;
    .logo {
        @include res(width, 100%, (mmd:90%, md:20%,xs:20%));
        @include res(margin-top, 50px, (md:30px,xs:25px));
        @include res(margin-left, 80px, (md:30px, xs:20px));
        a {
            width: 100%;
            display: block;
            img {
                display: block;
                max-width: fit-content;
                width: 100%;
            }
        }
    }
    .language {
        transition: all .3s;
        @include res(margin-top, 60px, (md:40px,xs:30px));
        @include res(margin-right, 280px, (md:200px,xs:155px));
        display: flex;
        align-items: center;
        i {
            transition: all .3s;
            color: #fff;
            margin-right: 5px;
            @include res(font-size, 20px, 16 / 20);
        }
        span {
            transition: all .3s;
            @include res(font-size, 20px, 16 / 20);
            font-family: 'ProximaNova-Bold';
            display: block;
            color: #fff;
        }
        &:hover {
            span {
                opacity: .8;
            }
            i {
                opacity: .8;
            }
        }
    }
    .menu {
        cursor: pointer;
        z-index: 999;
        @include res(width, 238px, (md:180px,xs:140px));
        height: 0;
        border-top-width: 0px;
        border-right-width: 0px;
        @include res(border-bottom-width, 80px, (md:60px,xs:40px));
        border-left-width: 20px;
        border-style: none solid solid;
        border-color: transparent transparent #01847F;
        position: absolute;
        @include res(top, 30px, (md:20px));
        right: 0;
        .content {
            width: 100%;
            @include res(height, 80px,(md:60px,xs:40px));
            display: flex;
            align-items: center;
            justify-content: center;
        }
        span {
            @include res(font-size, 20px, 14 / 20 );
            color: #fff;
            text-transform: uppercase;
            font-family: 'ProximaNova-Bold';
        }
        .line {
            width: 1px;
            height: 23px;
            background: rgba($color: #ffffff, $alpha: 0.3);
            @include res(margin-left, 25px, 15 / 25);
            @include res(margin-right, 30px, 15 / 30);
        }
        .hamburger {
            transition: all 0.3s;
            div {
                @include res(width, 34px, (md:25px));
                background: #fff;
                transition: all 0.3s;
                &:before {
                    transition: all .3s;
                    @include res(width, 34px, (md:25px));
                    background: #fff;
                }
                &:after {
                    transition: all 0.3s;
                    @include res(width, 25px, (md:15px));
                    background: #fff;
                }
            }
            view {
                @include res(width, 34px, (md:25px));
                background: #fff;

                &:after,
                &:before {
                    @include res(width, 34px, (md:25px));
                    background: #fff;
                }
            }
        }
        &:hover {
            .open-menu {
                div {
                    &:after {
                        @include res(width, 34px, (md:25px));
                    }
                }
            }
        }
    }
    .header-nav-mob {
        position: fixed;
        left: 100%;
        top: 0;
        z-index: 999;
        transition: all .5s;
        transform: translateX(0);
        @include res(width,688px,(md:500px,xs:400px));
        height: 100%;
        border-top-width: 0px;
        border-right-width: 0px;
        @include res(border-bottom-width, 1200px);
        @include res(border-left-width, 238px,(md:160px));
        border-style: none solid solid;
        border-color: transparent transparent #01847F;
        &.active {
            transform: translateX(-100%);
        }
        .top {
            position: absolute;
            @include res(top,58px,(md:40px));
            @include res(right, 53px, (md:25px));
            display: flex;
            align-items: center;
            span {
                font-family: 'ProximaNova-Bold';
                @include res(font-size, 20px, 16 / 20 );
                color: #fff;
                text-transform: uppercase;
                display: block;
                margin-right: 15px;
            }
            .hamburger {
                transition: all .3s;
                &:hover {
                    transform: rotate(90deg);
                }
                div {
                    @include res(width, 34px, (md:25px));
                    background: #fff;
                    transition: all 0.3s;
                    &:before {
                        transition: all .3s;
                        @include res(width, 34px, (md:25px));
                        background: #fff;
                    }
                    &:after {
                        transition: all 0.3s;
                        @include res(width, 34px, (md:25px));
                        background: #fff;
                    }
                }
            view {
                @include res(width, 34px, (md:25px));
                background: #fff;

                &:after,
                &:before {
                    @include res(width, 34px, (md:25px));
                    background: #fff;
                }
            }
            }
        }
        ul {
            position: absolute;
            @include res(top, 246px, (xs:180px));
            @include res(right, 50px, 30 / 50);
            @include res(width, 100%, (md:300px));
            li {
                .sub-nav-title {
                    span {
                        display: block;
                        text-align: right;
                        @include res(font-size, 80px, 40 / 80);
                        font-family: 'ProximaNova-Black';
                        color: #fff;
                        font-weight: bold;
                        font-style: oblique;
                        transition: all .3s;
                        &:hover {
                            color: rgba($color: #ffffff, $alpha: 0.7);
                        }
                    }
                }
                dl {
                    @include res(margin-top, 25px, 15 / 25);
                    @include res(margin-bottom, 30px, 15 / 30);
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    overflow: hidden;
                    dd {
                        position: relative;
                        text-transform: uppercase;
                        display: block;
                        @include res(font-size, 30px, 20 / 30);
                        color: #fff;
                        font-weight: bold;
                        @include res(margin-right, 35px, 20 / 35);
                        transition: all .3s;
                        &:hover {
                            span {
                                top: 0;
                                left: -15px;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    span {
                        transition: all .3s;
                        position: absolute;
                        left: 0px;
                        top: -40px;
                        display: block;
                        width: 3px;
                        height: 40px;
                        background: #fff;
                        transform: rotate(15deg);
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
.yinying {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    display: none;
}